import React, { useState, useEffect } from 'react';
import { Link, withRouter } from 'react-router-dom';
import routeConstants from './routeConstants';
import NavItem from './NavItem';

const Navbar = (props) => {
  const [collapsedNavbar, setCollapsedNavbar] = useState(true);
  const [filledNavbar, setFilledNavbar] = useState(false);

  const toggleNavbar = () => {
    setCollapsedNavbar(!collapsedNavbar);
  };

  const closeNavbar = () => {
    setCollapsedNavbar(true);
  };

  const {
    location: {
      pathname,
    }
  } = props;

  useEffect(() => {
    const handleNavbarScrolled = () => {
      if ((window.pageYOffset > 60)) {
        setFilledNavbar(true);
      } else {
        setFilledNavbar(false);
      }
    }
    window.addEventListener('scroll', handleNavbarScrolled);

    return () => {
      window.removeEventListener('scroll', handleNavbarScrolled);
    };
  }, [filledNavbar]);
  return (
    <nav className={`navbar navbar-expand-lg ${filledNavbar ? 'filled-nav' : ''}`} >
      <div className="container">
        <Link to='/' className="navbar-brand">
          <img src="https://imagizer.imageshack.com/img924/9769/4Wr639.png" alt="4ING Logo" className="navbar-title--logo" />
        </Link>
        <button
          className={`navbar-button ${collapsedNavbar ? '' : 'navbar-open'} ${collapsedNavbar? filledNavbar? '' : 'black-button' : ''} `}
          type="button"
          onClick={toggleNavbar}
          aria-label="Toggle navigation">
          <div className="line line-top" />
          <div className="line line-middle" />
          <div className="line line-bottom" />
        </button>
        <div className={`collapse navbar-collapse ${collapsedNavbar ? '' : 'show'}`}>
          <ul className="navbar-nav pt-2">
            {routeConstants.map(route => (
              <NavItem
                key={route.routeIndex}
                route={route.route}
                title={route.title}
                closeNavbar={closeNavbar}
                delay={`${route.routeIndex * 0.1}s`}
                name={route.name}
                pathname={pathname}
              />
            ))}
          </ul>
        </div>
      </div>
    </nav>
  );
}

export default withRouter(Navbar);