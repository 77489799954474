import React from 'react';
import Slider from 'react-slick';

import NextArrow from '../SliderArrows/NextArrow';
import PrevArrow from '../SliderArrows/PrevArrow';

const IntroSlider = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    fade: false,
    autoplay: true,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
  };

  const slides = [
    {
      slideImg: 'https://imagizer.imageshack.com/img924/9861/uUTqQu.jpg',
      slideLink: '/partneri'
    },
    {
      slideImg: 'https://imagizer.imageshack.com/img922/5800/8iiNho.jpg',
      slideLink: '/partneri'
    },
    {
      slideImg: 'https://imagizer.imageshack.com/img922/3236/mrfCLd.jpg',
      slideLink: '/partneri'
    },
    {
      slideImg: 'https://imagizer.imageshack.com/img922/4463/O2U8EK.jpg',
      slideLink: '/partneri'
    },
    {
      slideImg: 'https://imagizer.imageshack.com/img924/7637/tqQoTs.jpg',
      slideLink: '/partneri'
    },
    {
      slideImg: 'https://imagizer.imageshack.com/img922/825/ojKeuN.jpg',
      slideLink: '/partneri'
    },
    {
      slideImg: 'https://imagizer.imageshack.com/img923/637/lFbgDX.png',
      slideLink: '/partneri'
    },
    {
      slideImg: 'https://imagizer.imageshack.com/img924/8433/eB44Os.png',
      slideLink: '/partneri'
    },
    {
      slideImg: 'https://imagizer.imageshack.com/img924/8789/woIKvx.jpg',
      slideLink: '/partneri',
    },
    {
      slideImg: 'https://imagizer.imageshack.com/img923/8248/nNWcw4.jpg',
      slideLink: '/partneri',
    },
  ];

  return (
    <Slider {...settings}>
      {slides.map((slide) => (
        <div key={slide.slideTitle}>
          <div className="slide-img-wrapper">
            <img src={slide.slideImg} alt={slide.slideTitle} />
          </div>
        </div>
      ))}
    </Slider>
  );
}

const HeadingSection = () => {
  return (
    <div className="intro-section pb-6 bg-lighter">
      <div className="page-header">
        <div className="container">
          <h1 className="page-title">
            Vaš partner za grijanje, klimatizaciju i ventilaciju
          </h1>
        </div>
      </div>
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <IntroSlider />
          </div>
        </div>
      </div>
    </div>
  )
};

export default HeadingSection;
