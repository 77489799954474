import React, { useState } from 'react';
import Fade from 'react-reveal/Fade';
import axios from 'axios';
import { Icon, notification } from 'antd';

import './ContactUs.scss';

const ContactUsLanding = () => {
  const [state, setState] = useState({
    name: '',
    email: '',
    subject: '',
    message: '',
  });

  const handleInputChange = (e) => {
    setState({
      ...state,
      [e.target.id]: e.target.value
    })
  };

  const handleSubmit = () => {
    const data = {
      name: state.subject,
      email: state.email,
      message: state.message,
    }
    axios.post(`https://mailing-service-api-4ing.herokuapp.com/submit`,
    JSON.stringify(data),
    { headers: { 'Content-Type': 'application/json' } },
    )
      .then(res => {
        notification.success({
          description: 'Uspješno poslan e-mail.'
        })
        // console.log(res.data);
      }).catch((err) => {
        console.log(err)
        notification.error({
          description: 'Neuspješno poslan e-mail, molimo pokušajte opet.'
        })
      })
  }

  return (
    <div className="contact-us-section py-5 bg-lighter">
      <Fade bottom>
        <div className="container">
          <div className="row">
            <div className="col-lg-6 mb-2 mb-lg-0">
              <h3 className="text-center pb-5">Kontakt informacije</h3>
              <div className="row">
                <div className="col-md-6">
                  <h4>Sjedište</h4>
                  <ul className="contact-list">
                    <li>
                      <Icon type="environment" />
                      Trg međunarodnog prijateljstva br.25
                      71000 Sarajevo, Bosna i Hercegovina
                    </li>
                    <li>
                      <Icon type="phone" />
                      +387 33 610 933
                    </li>
                    <li>
                      <Icon type="mail" />
                      info@4ing.ba
                    </li>
                    <li>
                      <Icon type="file-text" />
                      ID broj: 4201860620006
                    </li>
                    <li>
                      <Icon type="file-text" />
                      PDV broj: 201860620006
                    </li>
                    <li>
                      <Icon type="file-text" />
                      Žiro račun kod Raiffeisen bank dd: 1610000106440051
                    </li>
                    <li>
                      <Icon type="file-text" />
                      MBS: 65-01-0289-13, Općinski sud u Sarajevu
                    </li>
                  </ul>
                </div>
                <div className="col-md-6">
                  <h4>Skladište</h4>
                  <ul className="contact-list">
                    <li>
                      <Icon type="environment" />
                      Aleja Bosne Srebrene br.28
                    </li>
                    <li>
                      <Icon type="phone" />
                      +387 33 610 933
                    </li>
                    <li>
                      <Icon type="clock-circle" />
                      Radno vrijeme: po dogovoru
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-12 col-lg-6 mt-5">
              <form>
                <div className="row">
                  <div className="col-12 col-lg-6">
                    <input
                      onChange={(e) => handleInputChange(e)}
                      type="text"
                      className="form-input-field"
                      id="name"
                      placeholder="Ime"
                      value={state.name}
                    />
                  </div>
                  <div className="col-12 col-lg-6">
                    <input
                      onChange={(e) => handleInputChange(e)}
                      type="email"
                      className="form-input-field"
                      id="email"
                      placeholder="E-Mail"
                      value={state.email}
                    />
                  </div>
                  <div className="col-12">
                    <input
                      onChange={(e) => handleInputChange(e)}
                      type="text"
                      className="form-input-field"
                      id="subject"
                      placeholder="Naslov"
                      value={state.subject}
                    />
                  </div>
                  <div className="col-12">
                    <textarea
                      onChange={(e) => handleInputChange(e)}
                      type="text"
                      rows="8"
                      className="form-input-field"
                      id="message"
                      placeholder="Poruka"
                      value={state.message}
                    >
                    </textarea>
                  </div>
                </div>
                <button type="button" onClick={handleSubmit} className="btn btn-outline-white">Pošalji</button>
              </form>
            </div>
            <div className="col-12 my-5">
              <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d23015.753000391058!2d18.365352370233047!3d43.856468608844494!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4758c9ac6421a571%3A0xd5a9997df8986f4b!2s4ING%20d.o.o.!5e0!3m2!1sen!2sba!4v1580155728340!5m2!1sen!2sba" width="100%" height="450" frameBorder="0" title="4ing Map" style={{ border: '0' }} allowFullScreen=""></iframe>
            </div>
          </div>
        </div>
      </Fade>
    </div>
  );
};

export default ContactUsLanding;
