import React from 'react';
import { Link } from 'react-router-dom';

const NavItem = ({ name, title, route, closeNavbar, delay, pathname }) => (
  <li className={`nav-item ${pathname === route ? 'active-nav-item' : "" }`} style={{animationDelay: delay}}>
    <Link
      className='nav-link'
      to={route}
      title={title}
      onClick={closeNavbar}
    >
      {title}
    </Link>
  </li>
);

export default NavItem;