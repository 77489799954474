import React from 'react';
import Fade from 'react-reveal/Fade';
import Slider from 'react-slick';

import NextArrow from '../SliderArrows/NextArrow';
import PrevArrow from '../SliderArrows/PrevArrow';

import { referenceSlides } from './slides';

const settings = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 3,
  slidesToScroll: 3,
  fade: false,
  autoplay: true,
  nextArrow: <NextArrow />,
  prevArrow: <PrevArrow />,
  className: 'references-slider',
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 3,
        infinite: true,
        dots: true
      }
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
        arrows: false,
        nextArrow: null,
        prevArrow: null,
        dots: false,
      }
    },
    {
      breakpoint: 480,
      settings: {
        arrows: false,
        nextArrow: null,
        prevArrow: null,
        dots: false,
        slidesToShow: 1,
        slidesToScroll: 1
      }
    }
  ]
};

const References = () => {

  return (
    <div className="references-section bg-lighter py-5">
      <div className="container">
        <Fade bottom>
          <h1 className="section-header py-5">Naše reference</h1>
        </Fade>
        <Fade bottom>
          <Slider {...settings}>
            {referenceSlides.map((slide) => (
              <div key={slide.slideTitle}>
                <div className="slide-img-wrapper">
                  <img src={slide.slideImg} alt={slide.slideTitle} />
                </div>
                <div className="slide-content">
                  <p className="slide-category">{slide.slideTitle}</p>
                </div>
              </div>
            ))}
          </Slider>
        </Fade>
      </div>
    </div>
  );
};

export default References;
