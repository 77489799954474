import React from 'react';
import PropTypes from 'prop-types';
import './App.css';

const App = ({ children }) => (
  <div className="app">
    <div className="content">{children}</div>
  </div>
);

App.defaultProps = {
  children: null,
};

App.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.arrayOf(PropTypes.node)]),
};

export default App;
