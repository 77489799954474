import React from 'react'

const Footer = () => {
  return (
    <footer className="footer">
      <div className="container">
        <p>Copyright © 2019 4ING D.O.O. Sva prava zadržana.</p>
      </div>
    </footer>
  )
};

export default Footer;
