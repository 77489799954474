import React from 'react'
import Fade from 'react-reveal/Fade';

import { Link } from 'react-router-dom';

import { siemensConstants } from '../Products/siemensConstants';

const ProductsOverview = (props) => {
  const filterConstants = (constants) => constants.find((sieConst) => (
    sieConst.name === props.match.params.category
  ))

  const getProducts = (filteredConstants) => (
    filteredConstants && filteredConstants.products.map((product) => {
      return (
        <div className="col-6 col-md-4 col-lg-3 col-xl-2" key={product.title}>
          <div className="product">
            <Link to={`/proizvodi/siemens/${props.match.params.category}/${product.title}`} className="product-image--wrapper">
              <img src={product.image} alt={product.title} />
            </Link>
            <div className="product-action">
              <a href={`/proizvodi/siemens/${props.match.params.category}/${product.title}`} className="btn-product">
                <span>vidi više</span>
              </a>
            </div>
            <div className="product-body">
              <p className="product-subtitle">SIEMENS</p>
              <h3 className="product-title">
                {product.title}
              </h3>
            </div>
          </div>
        </div>
      )
    })
  )

  return (
    <div className="intro-section">
      <div className="page-header">
        <div className="container">
          <h1 className="page-title">
            {props.match.params.category}
          </h1>
        </div>
      </div>
      <div className="container">
        <div className="row">
          <Fade bottom>
            {getProducts(filterConstants(siemensConstants))}
          </Fade>
        </div>
      </div>
    </div>
  )
}

export default ProductsOverview;
