import React, { Fragment } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

//import { NavRoute } from './routesHOC';
import LandingPage from './LandingPage';
import Navbar from './NavBar';
import Footer from './Footer';
import Products from './Products';
import ProductsOverview from './ProductsOverview';
import Contact from './Contact'
import AboutUs from './AboutUs';
import ScrollToTop from '../ScrollToTop';

export default () => (
  <Fragment>
    <Navbar />
    <ScrollToTop />
    <Switch>
      <Route
        path="/"
        exact
        component={LandingPage}
        />
        <Route
          path="/partneri"
          exact
          component={Products}
        />
        <Route
          path="/partneri/:manufacturer/:category"
          exact
          component={ProductsOverview}
        />
        <Route
          path="/kontakt"
          exact
          component={Contact}
        />
        <Route
          path="/o-nama"
          exact
          component={AboutUs}
        />
      <Route path="*">
        <Redirect to="/" />
      </Route>
    </Switch>
    <Footer />
  </Fragment>
);
