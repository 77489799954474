export const referenceSlides = [
  {
    slideImg: 'https://imagizer.imageshack.com/img924/6070/J7Opyr.jpg',
    slideTitle: 'Ćevabdžinica Bajra, Zenica – Automatska regulacija Siemens',
  },
  {
    slideImg: 'https://imagizer.imageshack.com/img921/8330/gQr40C.jpg',
    slideTitle: 'BH Telecom inženjering, Sarajevo – Automatska regulacija Siemens i centralni nadzor',
  },
  {
    slideImg: 'https://imagizer.imageshack.com/img923/3760/1d8Pc4.jpg',
    slideTitle: 'Bingo, Gornji Vakuf – Automatska regulacija Siemens',
  },
  {
    slideImg: 'https://imagizer.imageshack.com/img922/3456/II8HE5.jpg',
    slideTitle: 'Bingo, Maglaj – Automatska regulacija Siemens',
  },
  {
    slideImg: 'https://imagizer.imageshack.com/img922/3652/Q3fnQQ.jpg',
    slideTitle: 'BOSNAPLOD, Brčko – Klima komore Proklima, automatska regulacija Siemens',
  },
  {
    slideImg: 'https://imagizer.imageshack.com/img924/522/r85CY4.jpg',
    slideTitle: 'CEPS, Kiseljak – Rashladna mašina KTK, automatska regulacija Siemens',
  },
  {
    slideImg: 'https://imagizer.imageshack.com/img924/2641/m4zwUG.jpg',
    slideTitle: 'City Center Cazin, Cazin – Automatska regulacija Siemens',
  },
  {
    slideImg: 'https://imagizer.imageshack.com/img921/3912/saYmz4.jpg',
    slideTitle: 'Crvena jabuka, Bihać – Automatska regulacija Siemens',
  },
  {
    slideImg: 'https://imagizer.imageshack.com/img923/3553/3DOAY8.png',
    slideTitle: 'EMKA Feinguss, Mionica Srbija – Automatska regulacija Siemens',
  },
  {
    slideImg: 'https://imagizer.imageshack.com/img921/4531/EfgdaX.jpg',
    slideTitle: 'Ginex, Goražde – Rashladna mašina KTK, klima komore NOVAIR, automatska regulacija Siemens',
  },
  {
    slideImg: 'https://imagizer.imageshack.com/img924/1446/VUbea3.jpg',
    slideTitle: 'JUMBO, Mostar – Klima komora NOVAIR, automatska regulacija Siemens',
  },
  {
    slideImg: 'https://imagizer.imageshack.com/img924/5346/y5Rs7U.jpg',
    slideTitle: 'Klub 1, Sarajevo – Klima komora NOVAIR, automatska regulacija Siemens',
  },
  {
    slideImg: 'https://imagizer.imageshack.com/img923/7079/o7LV4W.jpg',
    slideTitle: 'Kulturni centar Trebinje, Trebinje – Klima komore WOLF, Automatska regulacija Siemens',
  },
  {
    slideImg: 'https://imagizer.imageshack.com/img921/9700/8tWjgf.jpg',
    slideTitle: 'Narodno pozorište, Sarajevo – Isporuka elemenata automatike Siemens',
  },
  {
    slideImg: 'https://imagizer.imageshack.com/img924/5019/qfwnzJ.jpg',
    slideTitle: 'Caffe slastičarna Panna Cotta, Ilidža – Klima komora WOLF, automatska regulacija Siemens',
  },
  {
    slideImg: 'https://imagizer.imageshack.com/img921/3482/IcRmyO.jpg',
    slideTitle: 'PREVENT, Goražde – Rashladne mašine KTK, Automatska regulacija Siemens',
  },
  {
    slideImg: 'https://imagizer.imageshack.com/img923/2163/1yvzth.png',
    slideTitle: 'Robot, Breza – Rashladna mašina KTK',
  },
  {
    slideImg: 'https://imagizer.imageshack.com/img922/7/4nbP3F.jpg',
    slideTitle: 'Robot, Hadžići – Rashladna mašina KTK',
  },
  {
    slideImg: 'https://imagizer.imageshack.com/img922/7295/3JHufp.jpg',
    slideTitle: 'Robot, Novi Travnik – Rashladna mašina KTK',
  },
  {
    slideImg: 'https://imagizer.imageshack.com/img921/8393/qFuIxJ.jpg',
    slideTitle: 'Sunnyland, Trebević – Automatska regulacija Siemens',
  },
  {
    slideImg: 'https://imagizer.imageshack.com/img922/6462/baYMMM.jpg',
    slideTitle: 'Veritas, Rajlovac – Rashladna mašina KTK, fancoili EPIVENT',
  },
  {
    slideImg: 'https://imagizer.imageshack.com/img921/7465/HEMi8n.jpg',
    slideTitle: 'Žičara, Sarajevo – Automatska regulacija Siemens',
  },
  {
    slideImg: 'https://imagizer.imageshack.com/img923/4061/suEMIv.jpg',
    slideTitle: 'JP Elektroprivreda, „Termoelektrana“ Kakanj – Automatska regulacija Siemens',
  },
  {
    slideImg: 'https://imagizer.imageshack.com/img921/7475/ULBRYs.jpg',
    slideTitle: 'MADI, Tešanj – Automatska regulacija Siemens',
  },
  {
    slideImg: 'https://imagizer.imageshack.com/img924/6182/Uq9rdU.jpg',
    slideTitle: 'Sinkro, Vlakovo – Kaloriferi WOLF',
  },
  {
    slideImg: 'https://imagizer.imageshack.com/img924/4135/vWawiR.jpg',
    slideTitle: 'Sinkro, Hrasnica – Kaloriferi WOLF',
  },

  {
    slideImg: 'https://imagizer.imageshack.com/img923/8177/uCWFlk.jpg',
    slideTitle: 'Amfiteatar fakulteta političkih nauka, Sarajevo - Automatska regulacija, Siemens',
  },
  {
    slideImg: 'https://imagizer.imageshack.com/img923/336/8M2IRn.jpg',
    slideTitle: 'ASA auto - Servisiranje i održavanje',
  },
  {
    slideImg: 'https://imagizer.imageshack.com/img921/933/W8nPmY.jpg',
    slideTitle: 'EMKA, Goražde - Klima komora Wolf, automatska regulacija Siemens, ventilatori Wolf, kaloriferi Wolf',
  },
  {
    slideImg: 'https://imagizer.imageshack.com/img921/5034/cZnlQT.jpg',
    slideTitle: 'HENKEL, Bileća - Klima komora Wolf, automatska regulacija Siemens',
  },
  {
    slideImg: 'https://imagizer.imageshack.com/img924/2349/yNYGQ5.jpg',
    slideTitle: 'Hotel SuperPetrol - Servisiranje',
  },
  {
    slideImg: 'https://imagizer.imageshack.com/img924/7230/dmU8yW.jpg',
    slideTitle: 'Islamski centar, Koševsko brdo, Sarajevo - Automatska regulacija Siemens',
  },
  {
    slideImg: 'https://imagizer.imageshack.com/img924/1173/4YxPY5.jpg',
    slideTitle: 'Moja banka - Servisiranje i održavanje',
  },
  {
    slideImg: 'https://imagizer.imageshack.com/img922/1263/wG5q96.jpg',
    slideTitle: 'Osnovna škola Srbija, Pale - Automatska regulacija Siemens',
  },
  {
    slideImg: 'https://imagizer.imageshack.com/img924/4850/OZBGsV.jpg',
    slideTitle: 'PIO/MIO, Zenica - Chiller, ventilatorski konvektori, automatska regulacija Siemens',
  },
  {
    slideImg: 'https://imagizer.imageshack.com/img921/8918/ph8mj3.jpg',
    slideTitle: 'Rudnik Ugljevik - Automatska regulacija Siemens',
  },
  {
    slideImg: 'https://imagizer.imageshack.com/img922/9896/HDzULn.jpg',
    slideTitle: 'Sportska dvorana Borik, Banja Luka - Automatska regulacija Siemens',
  },
  {
    slideImg: 'https://imagizer.imageshack.com/img921/7977/BZTDyG.jpg',
    slideTitle: 'SIPA, Istočno Sarajevo - Automatska regulacija Siemens',
  },
  {
    slideImg: 'https://imagizer.imageshack.com/img921/4816/5PphdM.jpg',
    slideTitle: 'Standard, Ilijaš - Klima komora Wolf, automatska regulacija Siemens',
  },
  {
    slideImg: 'https://imagizer.imageshack.com/img924/6242/OI0wBW.jpg',
    slideTitle: 'Studentski dom Pale - Automatska regulacija Siemens',
  },
  {
    slideImg: 'https://imagizer.imageshack.com/img923/3208/BghTzR.jpg',
    slideTitle: 'Tržni centar Konjic - servisiranje',
  },
  {
    slideImg: 'https://imagizer.imageshack.com/img921/3338/pqkMS9.png',
    slideTitle: 'Vinarija Vukoje, Trebinje - Automatska regulacija Siemens',
  },
  {
    slideImg: 'https://imagizer.imageshack.com/img921/1356/3JW44T.jpg',
    slideTitle: 'Volkswagen, Vogošća - Automatska regulacija Siemens',
  },
  {
    slideImg: 'https://imagizer.imageshack.com/img922/5997/We1EUX.jpg',
    slideTitle: 'Svadbeni salon Mostar - Klima komora Wolf, automatska regulacija Siemens',
  },
  {
    slideImg: 'https://imagizer.imageshack.com/img921/5696/74vBKm.jpg',
    slideTitle: 'Svadbeni salon Aurora, Lukavica - Klima komora Wolf, automatska regulacija Siemens',
  },
  {
    slideImg: 'https://imagizer.imageshack.com/img922/4427/GLdP64.jpg',
    slideTitle: 'Svadbeni salon Pale - Automatska regulacija Siemens',
  },
  {
    slideImg: 'https://imagizer.imageshack.com/img923/694/0B6IZI.jpg',
    slideTitle: 'Tvornica Biošamp, Šije - Ventilator BVN, automatska regulacija Siemens',
  },
  {
    slideImg: 'https://imagizer.imageshack.com/img924/576/y1Lves.jpg',
    slideTitle: 'Željeznice Federacije, Šije - Zračne zavjese, distribucija, kaloriferi, automatska regulacija Siemens',
  },
];
