export const siemensConstants = [
  {
    title: 'Ventili i pogoni ventila',
    link: 'https://hit.sbt.siemens.com/RWD/app.aspx?RC=HQEU&lang=en&MODULE=Product&ACTION=ShowGroup&KEY=HIT_Prod_Grp_52799',
  },
  {
    title: 'Sobni termostati',
    link: 'https://hit.sbt.siemens.com/RWD/app.aspx?RC=HQEU&lang=en&MODULE=Product&ACTION=ShowGroup&KEY=HIT_Prod_Grp_56662',
  },
  {
    title: 'Senzori',
    link: 'https://hit.sbt.siemens.com/RWD/app.aspx?RC=HQEU&lang=en&MODULE=Product&ACTION=ShowGroup&KEY=HIT_Prod_Grp_53399',
  },
  {
    title: 'Termostati',
    link: 'https://hit.sbt.siemens.com/RWD/app.aspx?RC=HQEU&lang=en&MODULE=Product&ACTION=ShowGroup&KEY=HIT_Prod_Grp_49053',
  },
  {
    title: 'Pogoni žaluzina',
    link: 'https://hit.sbt.siemens.com/RWD/app.aspx?RC=HQEU&lang=en&MODULE=Product&ACTION=ShowGroup&KEY=HIT_Prod_Grp_50249',
  },
  {
    title: 'Frekventni regulatori',
    link: 'https://hit.sbt.siemens.com/RWD/app.aspx?RC=HQEU&lang=en&MODULE=Product&ACTION=ShowGroup&KEY=HIT_Prod_Grp_52046',
  },
  {
    title: 'Kalorimetri',
    link: 'https://hit.sbt.siemens.com/RWD/app.aspx?RC=HQEU&lang=en&MODULE=Product&ACTION=ShowGroup&KEY=HIT_Prod_Grp_361186',
  },
  {
    title: 'Za projektante',
    link: 'https://new.siemens.com/global/en/products/buildings/partners/consultants.html',
  },

]

export const bvnConstants = [
  {
    title: 'Kanalski ventilatori',
    link: 'http://www.bvnair.com/en/index.php',
  },
  {
    title: 'Axijalni ventilatori',
    link: 'http://www.bvnair.com/en/index.php',
  },
  {
    title: 'Krovni ventilatori',
    link: 'http://www.bvnair.com/en/index.php',
  },
  {
    title: 'Radijalni ventilatori',
    link: 'http://www.bvnair.com/en/index.php',
  },
  {
    title: 'Ventilatori za odsis dima',
    link: 'http://www.bvnair.com/en/index.php',
  },
  {
    title: 'Plastični ventilatori',
    link: 'http://www.bvnair.com/en/index.php',
  },
  {
    title: 'Dodatna oprema',
    link: 'http://www.bvnair.com/en/index.php',
  },
]

export const wolfConstants = [
  {
    title: 'Oprema za ventilaciju kuća i stanova',
    link: 'https://www.wolf.eu/en/products/mechanical-ventilation-units/',
  },
  {
    title: 'Klima komore',
    link: 'https://www.wolf.eu/en/air-handling-expert/products-systems/',
  },
  {
    title: 'Kaloriferi',
    link: 'https://www.wolf.eu/fileadmin/Wolf_Daten/Dokumente/Technische_Dokus_EN/Ventilation/AIR_HEATER_LH-EC_LH_201907.pdf',
  },
]

export const ktkConstants = [
  {
    title: 'Rashladne mašine i toplotne pumpe',
    link: 'http://www.ktk.it/index.php?id=30&amp;lang_id=eng',
  },
]

export const novairConstants = [
  {
    title: 'Klima komore',
    link: 'http://www.novair.it/prodotti_catalogo_prodotti_elenco.php?mh=30&amp;ms=32&amp;lang=en',
  },
]

export const epiventConstants = [
  {
    title: 'Fancoili',
    link: 'http://www.epivent.it/prodotti-catalogo-elenco.php?m1=02&amp;m2=22&amp;msx=Product%20catalogue&amp;lang=en',
  },
  {
    title: 'Kasetne jedinice',
    link: 'http://www.epivent.it/prodotti-catalogo-elenco.php?m1=02&amp;m2=22&amp;msx=Product%20catalogue&amp;lang=en',
  },
  {
    title: 'Bloweri',
    link: 'http://www.epivent.it/prodotti-catalogo-elenco.php?m1=02&amp;m2=22&amp;msx=Product%20catalogue&amp;lang=en',
  },
]

export const ventasConstants = [
  {
    title: 'Fancoili',
    link: 'http://www.ventas.com.tr/urunler.html',
  },
  {
    title: 'Rekuperatori',
    link: 'http://www.ventas.com.tr/3+heat-recovery-units+urunler.html',
  },
  {
    title: 'Visoko efikasni rekuperatori',
    link: 'http://www.ventas.com.tr/20+high-efficiency-heat-recovery-units+urunler.html',
  },
  {
    title: 'Podni konvektori',
    link: 'http://www.ventas.com.tr/8+trench-heater+urunler.html',
  },
]

export const gmcConstants = [
  {
    title: 'Distributivni elementi za ventilaciju',
    link: 'http://www.gmcair.com/grilles.html',
  },
  {
    title: 'Regulacione žaluzine',
    link: 'http://www.gmcair.com/dampers.html',
  },
]

export const proKlimaConstants = [
  {
    title: 'Klima komore',
    link: 'http://www.proklima.hr/hr/naslovnica/',
  },
]
