import React, { Fragment } from 'react';
import HeadingSection from './components/HeadingSection';
import ProductsLanding from './components/ProductsLanding';
import References from './components/References';
import ContactUsLanding from './components/ContactUsLanding';

const LandingPage = () => {
  return (
    <Fragment>
      <HeadingSection />
      <ProductsLanding />
      <References />
      <ContactUsLanding />
    </Fragment>
  )
};

export default LandingPage;
