import React from 'react'
import { Icon } from 'antd';

const PrevArrow = (props) => {
  const { className, onClick } = props;
  return (
    <div
      className={`${className} custom-prev-arrow`}
      onClick={onClick}
      role="presentation"
    >
      <Icon type="left" />
    </div>
  );
};

export default PrevArrow;