import React from 'react';

const AboutUs = () => (
  <div className="intro-section bg-lighter">
    <div className="page-header">
      <div className="container">
        <h1 className="page-title">
          O nama
        </h1>
      </div>
    </div>
    <div className="container py-5">
      <p>
        Bavimo se grijanjem, klimatizacijom i ventilacijom zgrada i u tome imamo dugogodišnje iskustvo.
        Naša djelatnost obuhvata veleprodaju, održavanje i servisiranje opreme za grijanje, klimatizaciju
        i ventilaciju te pružanje usluga tehničke podrške pri projektovanju, ugradnji i puštanja u pogon
        automatike za KGH sisteme.
      </p>
      <p>
        Ovlašteni smo solution partner Siemens BT i za taj program radimo ugradnju, puštanje u pogon i distribuciju
        opreme za automatsku regulaciju. Vršimo pomoć pri odabiru Siemens opreme za ventilaciju klimatizaciju i grijanje.
      </p>
      <p>
        Partner smo renomiranog njemačkog proizvođača WOLF GmbH Mainburg i možemo ponuditi kvalificirano savjetovanje pri
        odabiru opreme, prodaju i servisiranje klima komora, grijača zraka, toplotnih pumpi, solarnih kolektora i druge
        opreme iz njihovog asortimana.
      </p>
      <p>
        Distributeri smo opreme italijanskog proizvođača G.I. Industrials koji nudi širok izbor rashladnih mašina i toplotnih
        pumpi kao i klima komora i fancoil uređaja.
      </p>
      <p>
        Naš partner VENTAS je proizvođač operme za ventilaciju među kojom najviše izdvajamo rekuperatore, visoko efikasne rekuperatore
        i podne konvektore.
      </p>
      <p>
        Sa našeg skladišta nudimo širok asortiman ventilatora turskog proizvođača BVN. Također prodajemo ventilatorske konvektore,
        fleksibilne cijevi, filtere, fleksibilne veze i ostalu KGH opremu.
      </p>
      <p>
        Na lageru našeg skladišta nalazi se i oprema za distribuciju kao i razni filteri i filter platna.
      </p>
    </div>
  </div>
);

export default AboutUs;
