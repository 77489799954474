import React from 'react';
import { Icon } from 'antd';
import Fade from 'react-reveal/Fade';

import {
  siemensConstants,
  bvnConstants,
  wolfConstants,
  ktkConstants,
  novairConstants,
  epiventConstants,
  ventasConstants,
  gmcConstants,
  proKlimaConstants
} from './siemensConstants';

const Products = (props) => {

  const goToProducts = (url) => {
    window.open(url, '_blank')
  }

  return (
    <div className="products-page intro-section bg-lighter pb-6">
      <div className="page-header">
        <div className="container">
          <h1 className="page-title">
            Partneri sa kojima radimo i proizvodi koje nudimo
          </h1>
        </div>
      </div>
      <div className="container">
        <div>
          <div className="partner-wrapper">
            <h3>Siemens</h3>
            <img src="https://imagizer.imageshack.com/v2/1024x768q90/924/TCjBTk.jpg" alt="Siemens logo" />
          </div>
          <div className="category-buttons--wrapper">
            {siemensConstants.map((c) => (
              <Fade>
                <button type="button" className="btn category-button" onClick={() => goToProducts(c.link)} key={c.title}>
                  {c.title}
                  <Icon type="right" />
                </button>
              </Fade>
            ))}
          </div>
        </div>
        <div>
          <div className="partner-wrapper">
            <h3>Wolf</h3>
            <img src="https://imagizer.imageshack.com/v2/1024x768q90/924/BjC4RJ.jpg" alt="WOLF logo" />
          </div>
          <div className="category-buttons--wrapper">
            {wolfConstants.map((c) => (
              <button type="button" className="btn category-button" onClick={() => goToProducts(c.link)} key={c.title}>
                {c.title}
                <Icon type="right" />
              </button>
            ))}
          </div>
        </div>
        <div>
          <div className="partner-wrapper">
            <h3>Proklima</h3>
            <img src="http://imageshack.com/a/img923/1060/EkRtYl.png" alt="Proklima logo" />
          </div>
          <div className="category-buttons--wrapper">
            {proKlimaConstants.map((c) => (
              <button type="button" className="btn category-button" onClick={() => goToProducts(c.link)} key={c.title}>
                {c.title}
                <Icon type="right" />
              </button>
            ))}
          </div>
        </div>
        <div>
          <div className="partner-wrapper">
            <h3>BVN</h3>
            <img src="https://imagizer.imageshack.com/v2/1024x768q90/921/ncsg70.jpg" alt="BVN logo" />
          </div>
          <div className="category-buttons--wrapper">
            {bvnConstants.map((c) => (
              <button type="button" className="btn category-button" onClick={() => goToProducts(c.link)} key={c.title}>
                {c.title}
                <Icon type="right" />
              </button>
            ))}
          </div>
        </div>
        <div>
          <div className="partner-wrapper">
            <h3>KTK</h3>
            <img src="https://imagizer.imageshack.com/img923/4927/pHWiwI.png" alt="KTK logo" />
          </div>
          <div className="category-buttons--wrapper">
            {ktkConstants.map((c) => (
              <button type="button" className="btn category-button" onClick={() => goToProducts(c.link)} key={c.title}>
                {c.title}
                <Icon type="right" />
              </button>
            ))}
          </div>
        </div>
        <div>
          <div className="partner-wrapper">
            <h3>Novair</h3>
            <img src="https://imagizer.imageshack.com/img922/8086/LEu1eE.png" alt="NOVAIR logo" />
          </div>
          <div className="category-buttons--wrapper">
            {novairConstants.map((c) => (
              <button type="button" className="btn category-button" onClick={() => goToProducts(c.link)} key={c.title}>
                {c.title}
                <Icon type="right" />
              </button>
            ))}
          </div>
        </div>
        <div>
          <div className="partner-wrapper">
            <h3>Epivent</h3>
            <img src="https://imagizer.imageshack.com/img923/8123/DzC3At.jpg" alt="Epivent logo" />
          </div>
          <div className="category-buttons--wrapper">
            {epiventConstants.map((c) => (
              <button type="button" className="btn category-button" onClick={() => goToProducts(c.link)} key={c.title}>
                {c.title}
                <Icon type="right" />
              </button>
            ))}
          </div>
        </div>
        <div>
          <div className="partner-wrapper">
            <h3>Ventas</h3>
            <img src="https://imagizer.imageshack.com/img922/3296/fqdZrH.png" alt="Ventas logo" />
          </div>
          <div className="category-buttons--wrapper">
            {ventasConstants.map((c) => (
              <button type="button" className="btn category-button" onClick={() => goToProducts(c.link)} key={c.title}>
                {c.title}
                <Icon type="right" />
              </button>
            ))}
          </div>
        </div>
        <div>
          <div className="partner-wrapper">
            <h3>GMC Air</h3>
            <img src="https://imagizer.imageshack.com/img924/3797/SRCMpd.png" alt="GMC Air logo" />
          </div>
          <div className="category-buttons--wrapper">
            {gmcConstants.map((c) => (
              <button type="button" className="btn category-button" onClick={() => goToProducts(c.link)} key={c.title}>
                {c.title}
                <Icon type="right" />
              </button>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Products;
