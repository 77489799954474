import React from 'react';
import Fade from 'react-reveal/Fade'
import { Link } from 'react-router-dom';

const ProductsLanding = () => {
  return (
    <div className="products-section py-5 bg-lighter">
      <div className="container">
        <Fade bottom>
          <h1 className="section-header py-5">Partneri sa kojima radimo</h1>
        </Fade>
        <div className="row justify-content-center">
          <Fade bottom>
            <div className="col-6 col-md-3">
              <div className="banner banner-cat">
                <Link className="card-wrapper" to="/partneri">
                  <img src="https://imagizer.imageshack.com/v2/1024x768q90/924/TCjBTk.jpg" alt="Siemens logo" />
                </Link>
              </div>
            </div>
          </Fade>
          <Fade bottom>
            <div className="col-6 col-md-3">
              <div className="banner banner-cat">
                <Link className="card-wrapper" to="/partneri">
                  <img src="https://imagizer.imageshack.com/v2/1024x768q90/924/BjC4RJ.jpg" alt="Wolf logo" />
                </Link>
              </div>
            </div>
          </Fade>
          <Fade bottom>
            <div className="col-6 col-md-3">
              <div className="banner banner-cat">
                <Link className="card-wrapper" to="/partneri">
                  <img className="" src="http://imageshack.com/a/img923/1060/EkRtYl.png" alt="Proklima logo" />
                </Link>
              </div>
            </div>
          </Fade>
          <Fade bottom>
            <div className="col-6 col-md-3">
              <div className="banner banner-cat">
                <Link className="card-wrapper" to="/partneri">
                  <img className="bvn-logo" src="https://imagizer.imageshack.com/v2/1024x768q90/921/ncsg70.jpg" alt="BVN logo" />
                </Link>
              </div>
            </div>
          </Fade>
          <Fade bottom>
            <div className="col-6 col-md-3">
              <div className="banner banner-cat">
                <Link className="card-wrapper" to="/partneri">
                  <img className="" src="https://imagizer.imageshack.com/img923/4927/pHWiwI.png" alt="KTK logo" />
                </Link>
              </div>
            </div>
          </Fade>
          <Fade bottom>
            <div className="col-6 col-md-3">
              <div className="banner banner-cat">
                <Link className="card-wrapper" to="/partneri">
                  <img className="" src="https://imagizer.imageshack.com/img922/8086/LEu1eE.png" alt="NOVAIR logo" />
                </Link>
              </div>
            </div>
          </Fade>
          <Fade bottom>
            <div className="col-6 col-md-3">
              <div className="banner banner-cat">
                <Link className="card-wrapper" to="/partneri">
                  <img className="" src="https://imagizer.imageshack.com/img923/8123/DzC3At.jpg" alt="Epivent logo" />
                </Link>
              </div>
            </div>
          </Fade>
          <Fade bottom>
            <div className="col-6 col-md-3">
              <div className="banner banner-cat">
                <Link className="card-wrapper" to="/partneri">
                  <img className="" src="https://imagizer.imageshack.com/img922/3296/fqdZrH.png" alt="Ventas logo" />
                </Link>
              </div>
            </div>
          </Fade>
          <Fade bottom>
            <div className="col-6 col-md-3">
              <div className="banner banner-cat">
                <Link className="card-wrapper" to="/partneri">
                  <img className="" src="https://imagizer.imageshack.com/img924/3797/SRCMpd.png" alt="GMC Air logo" />
                </Link>
              </div>
            </div>
          </Fade>
        </div>
      </div>
    </div>
  )
}

export default ProductsLanding;
