import React from 'react';

import ContactUs from '../LandingPage/components/ContactUsLanding/'

const Contact = () => (
  <div className="intro-section bg-lighter">
    <div className="page-header">
      <div className="container">
        <h1 className="page-title">
          Kontakt
        </h1>
      </div>
    </div>
    <ContactUs />
  </div>
);

export default Contact;