const routeConstants = [
  {
    routeIndex: 0,
    title: 'Početna',
    name: "home",
    route: '/',
  },
  {
    routeIndex: 1,
    title: 'O nama',
    name: "about",
    route: '/o-nama',
  },
  {
    routeIndex: 2,
    title: 'Partneri',
    name: "products",
    route: '/partneri',
  },
  {
    routeIndex: 3,
    title: 'Kontakt',
    name: "contact",
    route: '/kontakt',
  },
  // {
  //   routeIndex: 4,
  //   title: 'Testimonials',
  //   name: "testimonials",
  //   route: '/testimonials',
  // },
  // {
  //   routeIndex: 4,
  //   title: 'Gallery',
  //   name: "gallery",
  //   route: '/gallery',
  // },
  // {
  //   routeIndex: 5,
  //   title: 'Contact',
  //   name: "contact",
  //   route: '/contact',
  // },
  // {
  //   routeIndex: 6,
  //   title: 'Jobs',
  //   name: "jobs",
  //   route: '/jobs',
  // },
];

export default routeConstants;