import React from 'react'
import { Icon } from 'antd';

const NextArrow = (props) => {
  const { className, onClick } = props;
  return (
    <div
      className={`${className} custom-next-arrow`}
      onClick={onClick}
      role="presentation"
    >
      <Icon type="right" />
    </div>
  );
};

export default NextArrow;
